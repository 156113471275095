<template>
  <div class="table-filters">
    <PopoverTableFilter
      ref="popoverSettings"
      width="330"
      :placement="$direction === 'ltr' ? 'right-start' : 'left-start'"
      trigger="click"
      popper-class="column-settings-popover"
      :filter-name="$t('terms.supplierTerms.supplierTermTable.settings.display')"
      :filter-value="
        $t(
          `terms.supplierTerms.supplierTermTable.settings.${selectedPreset}.${
            selectedPreset === PRESETS.default ? 'newLabel' : 'label'
          }`
        )
      "
      :should-close-popover-on-scroll="true"
    >
      <SupplierTableDefaultDisplayIcon slot="icon" fill="#71767D" />
      <PopoverColumnSettings
        :table-columns="columns"
        :popover-visible="columnSettingsPopoverVisible"
        :presets-to-ignore="presetsToIgnore"
        :on-preset-changed="onPresetChange"
        :selected-comparison-mode="selectedComparisonMode"
        @on-apply="onUpdateColumnsAndAmountOfComparisons"
        @on-cancel="onCloseSettings"
      />
    </PopoverTableFilter>
    <DatePickerTableFilter
      v-for="(dateRangeIter, index) in dateRangesState"
      :key="`tableDateRange${index}`"
      :filter-name="$t('terms.supplierTerms.supplierTermTable.settings.dateRangePicker.period')"
      :filter-value="dateRangesLabel[index]"
      :min-width="dateRangesState.length > 1 ? '215px' : '180px'"
      :date-range="[dateRangeIter.startDate, dateRangeIter.endDate]"
      type="monthrange"
      format="MMM yy"
      size="small"
      :align="'center'"
      :clearable="false"
      :picker-options="{
        disabledDate: (args) => isDateInSupportedRange(args, index),
        shortcuts: datePickerShortcuts,
      }"
      :should-close-popover-on-scroll="true"
      @on-date-changed="(args) => onDateChanged(args, index)"
    >
      <component
        :is="getNumberIconComponentName(index + 1)"
        v-if="dateRangesState.length > 1"
        slot="icon"
        v-bind="{ size: 16, fill: '#9295A5' }"
      />
    </DatePickerTableFilter>
    <DropdownTableFilter
      v-if="showPurchaseListFilter"
      :filter-name="$t('terms.supplierTerms.supplierTermTable.header.purchaseList')"
      :filter-value="purchaseListFilterText"
      trigger="click"
      clearable
      :should-close-popover-on-scroll="true"
      @on-choose-item="onFilterPurchaseListStatus"
      @on-clear-filter="onFilterPurchaseListStatus(null)"
    >
      <div slot="filter-value" class="purchase-list-filter">
        <p>{{ purchaseListFilterText }}</p>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(purchaseListStatus, index) in Object.values(PURCHASE_LIST_STATUS)"
          :key="`status${index}`"
          :command="purchaseListStatus"
        >
          <div class="purchase-list-filter">
            <component
              :is="getPurchaseListStatusIconByStatus(purchaseListStatus)"
              :class="[purchaseListStatus]"
              :size="16"
            />
            <p>{{ $t(`terms.supplierTerms.supplierTermTable.${purchaseListStatus}`) }}</p>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </DropdownTableFilter>
  </div>
</template>
<script>
import { ref, computed, getCurrentInstance, watch } from 'vue';
import { DateTime, Interval } from 'luxon';

import { PopoverTableFilter, DatePickerTableFilter, TableFilter, DropdownTableFilter } from '@/modules/core';
import {
  SupplierTableDefaultDisplayIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
  CheckIcon,
  DisabledIcon,
  NeutralIcon,
} from '@/assets/icons';

import {
  getDefaultPreset as supplierDefaultPreset,
  getComparisonPreset as supplierComparisonPreset,
} from './suppliersPurchaseManagement/suppliersTableColumns';

import PopoverColumnSettings from './PopoverColumnSettings';

import {
  getDefaultPreset as productsDefaultPreset,
  getComparisonPreset as productsComparisonPreset,
  getSimulationPreset as productsSimulationPreset,
} from './supplierProductsPurchaseManagement/supplierProductsTableColumns';

import { getNumberIconComponentName } from '../purchaseManagement';
import {
  PRESETS,
  datePickerShortcutsConfiguration,
  PRESET_TYPES,
  generate3MonthsBackwardDateRange,
  generate1YearBackwardsDateRange,
} from '@/modules/purchase-management/tools/constants';

import { PURCHASE_LIST_STATUS } from './supplierProductsPurchaseManagement/supplierTableCells/PurchaseListCell.vue';

export default {
  name: 'DisplayModeDateViews',
  components: {
    SupplierTableDefaultDisplayIcon,
    OneIcon,
    TwoIcon,
    ThreeIcon,
    CheckIcon,
    DisabledIcon,
    NeutralIcon,
    PopoverTableFilter,
    DatePickerTableFilter,
    TableFilter,
    PopoverColumnSettings,
    DropdownTableFilter,
    getNumberIconComponentName,
  },
  props: {
    columns: { type: Array, required: true, default: () => [] },
    dateRangesLabel: { type: Array, required: true, default: () => [] },
    dateRangesState: { type: Array, required: true, default: () => [] },
    showPurchaseListFilter: { type: Boolean, default: false },
    presetsToIgnore: { type: Array, required: false, default: () => [] },
    presetType: { type: String, required: true, validator: (type) => Object.values(PRESET_TYPES).includes(type) },
  },
  emits: ['on-columns-update', 'on-date-range-update', 'on-purchase-list-filter-change'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const { $i18n } = root;

    const selectedPreset = ref(PRESETS.default);
    const popoverSettings = ref(null);
    const selectedComparisonMode = ref(Number(root.$route.query?.periodNum || 1));
    const purchaseListFilterText = ref(null);

    const columnSettingsPopoverVisible = computed(() =>
      popoverSettings.value ? popoverSettings.value.isPopoverVisible : false
    );

    watch(
      () => root.$route.query,
      (queryParams) => {
        if (!queryParams.periodNum) resetDefaultsIfQueryParamsAreEmpty();
      },
      { deep: true }
    );

    const resetDefaultsIfQueryParamsAreEmpty = () => {
      emit('on-date-range-update', []);
      selectedPreset.value = PRESETS.default;
      selectedComparisonMode.value = 1;
    };

    const onPresetChange = (columns, preset) => {
      if (props.presetType === PRESET_TYPES.SUPPLIER) {
        return changeSupplierPreset(columns, preset);
      }

      if (props.presetType === PRESET_TYPES.PRODUCTS) {
        return changeProductsPreset(columns, preset);
      }
    };

    const changeSupplierPreset = (columns, preset) => {
      switch (preset) {
        case PRESETS.default:
          return {
            comparisonMode: 1,
            updatedColumns: supplierDefaultPreset(columns),
          };
        case PRESETS.comparison:
          return {
            comparisonMode: 2,
            updatedColumns: supplierComparisonPreset(columns),
          };

        default:
          return {
            comparisonMode: 1,
            updatedColumns: supplierDefaultPreset(columns),
          };
      }
    };

    const changeProductsPreset = (columns, preset) => {
      switch (preset) {
        case PRESETS.default:
          return {
            comparisonMode: 1,
            updatedColumns: productsDefaultPreset(columns),
          };
        case PRESETS.comparison:
          return {
            comparisonMode: 2,
            updatedColumns: productsComparisonPreset(columns),
          };
        case PRESETS.simulation:
          return {
            comparisonMode: 1,
            updatedColumns: productsSimulationPreset(columns),
          };

        default:
          return {
            comparisonMode: 1,
            updatedColumns: productsDefaultPreset(columns),
          };
      }
    };

    const onCloseSettings = () => popoverSettings?.value?.closePopover();

    const onUpdateColumnsAndAmountOfComparisons = ({ amountOfComparisons, columnsToUpdate, preset: newPresetMode }) => {
      selectedPreset.value = selectedPreset.value !== newPresetMode ? PRESETS[newPresetMode] : PRESETS.custom;
      selectedComparisonMode.value = amountOfComparisons;

      emit('on-columns-update', columnsToUpdate);

      const [initialRangeState] = props.dateRangesState;
      const { startDate, endDate } = initialRangeState;
      const query = root.$route.query;

      const newDateRangesState = [];
      let dateTimeToPush;

      for (let index = 0; index < amountOfComparisons; index++) {
        if (index === 0) dateTimeToPush = initialRangeState;
        if (index === 1) dateTimeToPush = generate3MonthsBackwardDateRange({ query, index, startDate });
        if (index === 2) dateTimeToPush = generate1YearBackwardsDateRange({ query, index, startDate, endDate });
        if (index > 2) break;

        newDateRangesState.push(dateTimeToPush);
      }

      onUpdateQueryParams(newDateRangesState);
      onCloseSettings();
      emit('on-date-range-update', newDateRangesState);
    };

    const datePickerShortcuts = [
      {
        text: root.$i18n.t(`terms.supplierTerms.supplierTermTable.settings.dateShortcuts.dateShortcutsTitle`),
      },
      ...datePickerShortcutsConfiguration().map(({ startDate, endDate, text }) => ({
        text,
        onClick(datePicker) {
          datePicker.$emit('pick', [startDate.toJSDate(), endDate.toJSDate()]);
        },
      })),
    ];

    const isDateInSupportedRange = (dateToValidate, index) => {
      if (!props.dateRangesState[index]) return false;

      const { startDate, endDate } = props.dateRangesState[index];
      if (startDate && endDate) return false;
      if (startDate) {
        const startDateTime = DateTime.fromJSDate(new Date(startDate));
        const oneYearAgo = startDateTime.minus({ year: 1 });
        const oneYearInTheFuture = startDateTime.plus({ year: 1, month: 1 }); // Since luxon does not include end date in its interval, we add a month to support this

        const interval = Interval.fromDateTimes(oneYearAgo, oneYearInTheFuture);

        return !interval.contains(dateToValidate);
      }
      return false;
    };

    const onDateChanged = (dateRangeToUpdate, index) => {
      const startDate = DateTime.fromJSDate(dateRangeToUpdate[0]).startOf('month');
      const endDate = DateTime.fromJSDate(dateRangeToUpdate[1]).endOf('month');

      const newDateRangesState = props.dateRangesState.map((item, idx) => {
        if (idx === index) {
          return { startDate, endDate };
        }
        return item;
      });
      onUpdateQueryParams(newDateRangesState);
      emit('on-date-range-update', newDateRangesState);
    };

    const onFilterPurchaseListStatus = (filter) => {
      if (filter === null) purchaseListFilterText.value = null;
      else purchaseListFilterText.value = $i18n.t(`terms.supplierTerms.supplierTermTable.${filter}`);

      emit('on-purchase-list-filter-change', filter);
    };

    const getPurchaseListStatusIconByStatus = (status) => {
      if (status === PURCHASE_LIST_STATUS.APPROVED) return 'CheckIcon';
      if (status === PURCHASE_LIST_STATUS.DISABLED) return 'DisabledIcon';
      return 'NeutralIcon';
    };

    const onUpdateQueryParams = (newDateRangesState) => {
      const isPeriodNumGreaterThanNewDateRangesState = Number(root.$route.query?.periodNum) > newDateRangesState.length;

      const query = isPeriodNumGreaterThanNewDateRangesState
        ? { periodNum: newDateRangesState.length }
        : { ...root.$route.query, periodNum: newDateRangesState.length };

      newDateRangesState.forEach(({ startDate, endDate }, index) => {
        const prevState = props.dateRangesState[index];

        if (prevState) {
          const { startDate: prevStateStartDate, endDate: prevStateEndDate } = prevState;

          const datesChanged = prevStateStartDate.ts !== startDate.ts || prevStateEndDate.ts !== endDate.ts;

          const hasQueryDates = root.$route.query[`fromDate${index}`] && root.$route.query[`toDate${index}`];

          if (datesChanged || hasQueryDates) {
            query[`fromDate${index}`] = startDate.toISODate();
            query[`toDate${index}`] = endDate.toISODate();
          }
        }
      });

      root.$router.replace({ query });
    };

    return {
      PRESETS,
      PURCHASE_LIST_STATUS,
      purchaseListFilterText,
      selectedPreset,
      onPresetChange,
      popoverSettings,
      columnSettingsPopoverVisible,
      onCloseSettings,
      selectedComparisonMode,
      onUpdateColumnsAndAmountOfComparisons,
      datePickerShortcuts,
      getNumberIconComponentName,
      isDateInSupportedRange,
      onDateChanged,
      onFilterPurchaseListStatus,
      getPurchaseListStatusIconByStatus,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-picker-panel__shortcut {
    word-break: break-word;
  }
  .el-picker-panel__body {
    margin-inline-start: 190px !important;
  }
  .el-picker-panel__sidebar {
    width: 200px;
    padding: 16px;
  }
  .el-picker-panel__sidebar > .el-picker-panel__shortcut:first-child {
    color: #606266;
    font-weight: bold;
    margin-bottom: 4px;
    cursor: default;
  }
}

.table-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.purchase-list-filter {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}
</style>
