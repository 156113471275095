<template>
  <el-tooltip v-if="hasDiscount" placement="top" :offset="offset" effect="dark">
    <div slot="content" class="discount-tooltip">
      <p v-if="orderItem.discount && orderItem.discount != 0">
        {{ `${$t('terms.supplierTerms.supplierTermTable.itemDiscount')}: ${formatPercent(orderItem.discount)}` }}
      </p>
      <p v-if="orderItem.orderDiscountRate && orderItem.orderDiscountRate != 0">
        {{
          `${$t('terms.supplierTerms.supplierTermTable.orderDiscount')}: ${formatPercent(
            orderItem.orderDiscountRate * 100
          )}`
        }}
      </p>
    </div>
    <div>
      <p>
        {{ formatPercent(mergedPercentages) }}
      </p>
    </div>
  </el-tooltip>
  <p v-else class="merged-percentage">{{ MISSING_DATA_TEXT }}</p>
</template>
<script>
import { computed } from 'vue';
import { formatPercent, returnDashInsteadOfNull } from '../purchaseManagementFormatters';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
export default {
  name: 'DiscountCell',
  props: {
    orderItem: { type: Object, default: () => undefined },
    offset: { type: Number, default: -20 },
  },
  setup(props) {
    const mergedPercentages = computed(() => {
      const itemDiscountRate = props.orderItem.discount / 100;
      const mergedRate =
        itemDiscountRate + props.orderItem.orderDiscountRate - itemDiscountRate * props.orderItem.orderDiscountRate;

      return mergedRate * 100;
    });

    const hasDiscount = computed(
      () =>
        props.orderItem &&
        ((props.orderItem.discount && props.orderItem.discount != 0) ||
          (props.orderItem.orderDiscountRate && props.orderItem.orderDiscountRate != 0))
    );

    return {
      MISSING_DATA_TEXT,
      mergedPercentages,
      hasDiscount,
      formatPercent: (value) => returnDashInsteadOfNull(formatPercent(value)),
    };
  },
};
</script>
<style lang="scss" scoped>
.discount-tooltip {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 6.5rem;

  p {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}
</style>
