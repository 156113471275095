<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('routes.suppliersPurchaseManagement') }}</h1>
      <p class="fw-bold">{{ $t('terms.supplierTerms.purchaseHistoryTop20.header') }}</p>
    </template>
    <template #dashboard>
      <div class="d-flex justify-content-center align-items-center">
        <SupplierPurchasesPie class="h-100 col-6 mx-1" :full-view="true" />
        <PriceChangeImpactSlider
          v-loading="loading"
          :minimum="-50"
          :maximum="50"
          show-marks
          :base-mark-value="estimatedAnnualPurchaseAmountSum"
          @change="sliderValue = $event"
          class="col-6 mx-1"
        />
      </div>
    </template>
    <TopPurchasedProductsTable
      :loading="loading"
      :top-purchased-products="transformedTopPurchasedProducts"
      :price-reduction="sliderValue"
    />
  </PageLayout>
</template>

<script>
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';

import { PageLayout } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

import SupplierPurchasesPie from './components/supplierPurchases/SupplierPurchasesPie';
import { useTopPurchasedProducts } from './components/topPurchasedProducts/compositions/useTopPurchasedProducts';
import TopPurchasedProductsTable from './components/topPurchasedProducts/components/TopPurchasedProductsTable';
import PriceChangeImpactSlider from './components/topPurchasedProducts/components/PriceChangeImpactSlider';

export default {
  components: { PageLayout, PriceChangeImpactSlider, SupplierPurchasesPie, TopPurchasedProductsTable },
  props: {
    limit: { type: Number, default: 20 },
  },
  setup(props) {
    const { currentTenant } = useTenancy();
    const toDate = DateTime.local().startOf('month');
    const fromDate = toDate.minus({ months: 6 }).toFormat('yyyy-MM-dd');
    const { topPurchasedProducts, loading } = useTopPurchasedProducts(
      computed(() => ({
        tenantId: currentTenant.value.id,
        limit: props.limit,
        fromDate,
        toDate: toDate.minus({ days: 1 }).toFormat('yyyy-MM-dd'),
      }))
    );
    return {
      topPurchasedProducts,
      loading,
      sliderValue: ref(0),
    };
  },
  computed: {
    transformedTopPurchasedProducts() {
      return this.topPurchasedProducts.map(
        ({ product, supplier, monthlyAverageTotalQuantity, pricedOrderItemAverage }) => {
          const estimatedAnnualQuantity = monthlyAverageTotalQuantity * 12;
          const averagePrice = pricedOrderItemAverage;
          const price = averagePrice;
          const priceImpact = price - price * ((100 + this.sliderValue) / 100);

          return {
            product,
            supplier,
            estimatedAnnualQuantity,
            averagePrice,
            estimatedAnnualPurchaseAmount: estimatedAnnualQuantity * price,
            annualImpact: priceImpact * estimatedAnnualQuantity,
            targetPrice: price - priceImpact,
          };
        }
      );
    },
    estimatedAnnualPurchaseAmountSum() {
      return this.transformedTopPurchasedProducts.reduce(
        (sum, currentProduct) => sum + currentProduct.estimatedAnnualPurchaseAmount,
        0
      );
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard ::v-deep .card {
  height: 13.375rem;
}
</style>
