import { DateTime } from 'luxon';

const TERM_PROMOTION_TYPE = {
  DISCOUNT: 'DISCOUNT',
};

const TERM_TYPE = {
  PROMOTION: 'PROMOTION',
  PRICING: 'PRICING',
};

const TERM_PRICING_METHOD = {
  FIXED_PRICE: 'FIXED_PRICE',
  PRICE_INDEX: 'PRICE_INDEX',
  PER_ORDER: 'PER_ORDER',
  PRICE_LIST: 'PRICE_LIST',
  UNKNOWN: 'UNKNOWN',
};

export const calculateTerm = (terms) => {
  if (!terms.length) return null;

  const pricingTerm = terms.find((term) => term.type === TERM_TYPE.PRICING);
  if (!pricingTerm) return null;

  const promotionTerm = terms.find((term) => term.type === TERM_TYPE.PROMOTION);
  const discount = promotionTerm?.rewardType === TERM_PROMOTION_TYPE.DISCOUNT ? promotionTerm.rewardValue : 0;
  const today = DateTime.utc().startOf('day').toJSDate();
  const price =
    pricingTerm?.pricingMethod === 'PRICE_LIST'
      ? pricingTerm?.priceList.prices[0].data
          .filter(({ date }) => new Date(date) <= today)
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]?.price
      : pricingTerm?.price;

  switch (pricingTerm.pricingMethod) {
    case TERM_PRICING_METHOD.FIXED_PRICE:
    case TERM_PRICING_METHOD.PRICE_LIST:
      return {
        pricingMethod: pricingTerm.pricingMethod,
        price,
        netPrice: (price * (100 - discount)) / 100,
        discount,
      };
    case TERM_PRICING_METHOD.PRICE_INDEX:
      return {
        pricingMethod: pricingTerm.pricingMethod,
        priceIndexName: pricingTerm.priceIndexName,
        priceIndexOffset: pricingTerm.priceIndexOffset,
      };
    case TERM_PRICING_METHOD.PER_ORDER:
      return {
        pricingMethod: pricingTerm.pricingMethod,
        type: pricingTerm.pricingMethod,
      };
  }
};
