<template>
  <Carousel
    ref="carousel"
    v-model="page"
    :class="{ ['hover-navigation']: hoverNavigation }"
    :navigate-to="page"
    navigation-enabled
    :per-page="perPage"
    :mouse-drag="false"
    :rtl="$t('direction') === 'rtl'"
    :pagination-size="6"
    :pagination-padding="4"
    :pagination-active-color="styles.typographySecondary"
    :pagination-color="styles.typographyDisabled"
  >
    <slot />
    <template #navigation>
      <Button
        v-show="page !== 0"
        circle
        class="position-absolute p-1 navigation navigation-right"
        :style="{ top: `calc(50% - ${navigationTop}px)` }"
        @click="page = page + NAVIGATION_DIRECTION.BACKWARD"
      >
        <ChevronIcon :direction="$t('direction') === 'rtl' ? 'right' : 'left'" />
      </Button>
      <Button
        v-show="page !== pageCount"
        circle
        class="position-absolute p-1 navigation navigation-left"
        :style="{ top: 'calc(50% - 40px)' }"
        @click="page = page + NAVIGATION_DIRECTION.FORWARD"
      >
        <ChevronIcon :direction="$t('direction') === 'rtl' ? 'left' : 'right'" />
      </Button>
    </template>
  </Carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { Button } from '@/modules/core/components';
import { ChevronIcon } from '@/assets/icons';
import * as styles from '@/stylesheets/scss/typography.module.scss';

const NAVIGATION_DIRECTION = {
  BACKWARD: -1,
  FORWARD: 1,
};

export { Slide };
export default {
  components: { Carousel, Button, ChevronIcon },
  props: {
    perPage: { type: Number, default: 3 },
    navigationTop: { type: Number, default: 40 },
    hoverNavigation: { type: Boolean, default: true },
  },
  data() {
    return {
      NAVIGATION_DIRECTION,
      styles,
      page: 0,
      isMounted: false,
      isMouseOverCarousel: false,
    };
  },
  computed: {
    pageCount() {
      if (!this.isMounted) return;
      return this.$refs.carousel.pageCount - 1;
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style scoped lang="scss">
.navigation {
  [dir='rtl'] & {
    &-right {
      right: -8px;
    }
    &-left {
      left: -8px;
    }
  }

  [dir='ltr'] & {
    &-right {
      left: -8px;
    }
    &-left {
      right: -8px;
    }
  }
}

.hover-navigation {
  .navigation {
    visibility: hidden;
  }

  &:hover {
    .navigation {
      visibility: visible;
    }
  }
}
</style>
